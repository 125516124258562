import React from "react"
import styled from "styled-components";
import { Flex,Span } from "../../utils/styles.util";
import blocksImg from "../../assets/blocks.png"
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { COLORS } from "../../utils/constant.util";

const DivParentFooter = styled.div`
    position: relative !important;
    text-align:right;
`

const DivChildFooter  = styled.div`
    background-color:${COLORS.light_grey};
    clip-path: polygon(0% 45%, 100% 0, 100% 100%, 0% 100%, 0 44%);
    -webkit-clip-path:polygon(-50% 45%, 100% 0, 100% 100%, 0% 100%, 0 44%);
    padding-top:5rem;
    padding-bottom:3rem;
    padding-right:1rem;
`

const Hexagonal = styled.img`
    width: 100vw;
    z-index: -1;
    right:0;
    position: absolute;
    height: 40%;
    object-fit: cover;
    object-position: 100% 100%;
    -webkit-transform:rotate(160deg);
    -moz-transform: rotate(160deg);
    -ms-transform: rotate(160deg);
    -o-transform: rotate(160deg);
    transform: rotate(182deg);
`


export default function Footer(){
    return(
        <DivParentFooter>
            <Hexagonal src={blocksImg}/>
            <DivChildFooter>
                <Flex column alignEnd>
                    <Span medium bold>Contact Us</Span>
                    <Span small>Jl. Prajurit KKO Usman dan Harun No.30A</Span>
                    <Span small>Senen, Jakarta Pusat, 10110</Span>
                    <Span small>Call +62 81317778256</Span>
                    <Span>
                        <Link to={{ pathname: "https://instagram.com/cascadeinteriorcontractor" }} target="_blank" style={{ color: 'black' }} >
                            <Icon.Instagram />
                        </Link> 
                        <Link to={{ pathname: "https://www.youtube.com/@cascadeinteriorcontractor3515" }} target="_blank" style={{ color: 'black',padding:5 }} >
                            <Icon.Youtube />
                        </Link> 
                    </Span>
                </Flex>
            </DivChildFooter>
        </DivParentFooter>
    )
}

