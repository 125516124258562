const menuSections = {
    HOME: "home",
    ABOUTUS: "aboutus",
    SERVICES: "services",
    PROJECTS: "projects",
    SHOPPING: "shopping",
    CONTACT: "contact"
}

const ContentType = {
    image: 1,
    video: 2
}

const navbarSections = [
    {
        menuSection: menuSections.HOME,
        route: "/",
        title: "HOME"
    },
    {
        menuSection: menuSections.ABOUTUS,
        route: "/aboutus",
        title: "ABOUT US"
    },
    {
        menuSection: menuSections.SERVICES,
        route: "/services",
        title: "SERVICES"
    },
    {
        menuSection: menuSections.PROJECTS,
        route: "/portfolio",
        title: "PORTOFOLIO"
    },
    {
        menuSection: menuSections.SHOPPING,
        route: "/shopping",
        title: "SHOPPING"
    },
    {
        menuSection: menuSections.CONTACT,
        route: "/contact",
        title: "CONTACT"
    }
]

const COLORS = {
    text: "black",
    primary: "#CE9B64",
    secondary: "#E8CB9F",
    white: "white",
    grey:"#AAAAAA",
    light_grey:"#e3e3e3"
}

const SIZE = {
    tiny:"0.7rem",
    small: "1rem",
    medium: "1.5rem",
    big: "2rem"
}

const AuthToken = "AuthToken"

const POPUPTYPE = {
    error: 0,
    success: 1,
    info: 2
}

const PortofolioCategories = [
    {name:"Rumah Tinggal",value:'1'},
    {name:"Retail",value:'2'},
    {name:"Kantor",value:'3'},
    {name:"Apartemen",value:'4'},
    {name:"Konstruksi",value:'5'}
]

const ProductCategories = [
    {name:"Category A",value:'1'},
    {name:"Category B",value:'2'},
    {name:"Category C",value:'3'},
    {name:"Category D",value:'4'}
]

export {
    navbarSections,
    menuSections,
    ContentType,
    COLORS,
    SIZE,
    AuthToken,
    POPUPTYPE,
    PortofolioCategories,
    ProductCategories
} 