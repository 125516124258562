import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import mainteam1 from '../../assets/team/Website Photo-20.jpg'
import mainteam2 from '../../assets/team/Website Photo-23.jpg'
import mainteam3 from '../../assets/team/Website Photo-12.jpg'
import mainteam4 from '../../assets/team/Website Photo-07.jpg'
import mainteam5 from '../../assets/team/Website Photo-03.jpg'
import mainteam6 from '../../assets/team/Website Photo-05.jpg'
import mainteam7 from '../../assets/team/Website Photo-14.jpg'
import mainteam8 from '../../assets/team/Website Photo-02.jpg'
import mainteam9 from '../../assets/team/Website Photo-22.jpg'
import mainteam10 from '../../assets/team/Website Photo-08.jpg'
import mainteam11 from '../../assets/team/Website Photo-01.jpg'
import mainteam12 from '../../assets/team/Website Photo-04.jpg'
import mainteam13 from '../../assets/team/Website Photo-09.jpg'
import mainteam14 from '../../assets/team/Website Photo-06.jpg'
import mainteam15 from '../../assets/team/Website Photo-13.jpg'
import mainteam16 from '../../assets/team/Website Photo-15.jpg'
import mainteam17 from '../../assets/team/Website Photo-21.jpg'
import mainteam18 from '../../assets/team/Website Photo-10.jpg'
import mainteam19 from '../../assets/team/Website Photo-11.jpg'
import mainteam20 from '../../assets/team/Website Photo-19.jpg'
import mainteam21 from '../../assets/team/Website Photo-18.jpg'
import mainteam22 from '../../assets/team/Website Photo-16.jpg'
import mainteam23 from '../../assets/team/Website Photo-17.jpg'


const ImgMainTeam = styled.img`
    width:250px;
    height:400px;
`

export default function OurTeam(){
    const mainTeamList = [
        [
            {
                photo: mainteam1
            },
            {
                photo: mainteam2
            },{
                photo: mainteam3
            },
            {
                photo: mainteam4
            }
        ],
        [
            {
                photo: mainteam5
            },
            {
                photo: mainteam6
            },
            {
                photo: mainteam7
            },
            {
                photo: mainteam8
            }
        ],
        [
            {
                photo: mainteam9
            },
            {
                photo: mainteam10
            },
            {
                photo: mainteam11
            },
            {
                photo: mainteam12
            }
        ],
        [
            {
                photo: mainteam13
            },
            {
                photo: mainteam14
            },
            {
                photo: mainteam15
            },
            {
                photo: mainteam16
            }
        ],
        [
            {
                photo: mainteam17
            },
            {
                photo: mainteam18
            }        , {
                photo: mainteam19
            },
            {
                photo: mainteam20
            }
        ],
        [
            {
                photo: mainteam21
            },
            {
                photo: mainteam22
            },
            {
                photo: mainteam23
            }
        ]
    ]

    return(
        <Container>
                {
                    mainTeamList.map((mainTeam,i) => (
                      <div key={i}>
                      <Row  className="row-our-team">
                          {
                              mainTeam.map((main,i)=>(
                                <Col key={i} className="col-our-team">
                                    <ImgMainTeam src={main.photo}/>  
                                </Col>
                              ))
                          }
                      </Row>
                      <br/>
                      </div>
                    ))
                }
            </Container>
    );
}